/* global tns */
window.addEventListener('DOMContentLoaded', () => {
  // MODALS
  const modalWindows = document.querySelectorAll('.modal');

  function hideModal(modal) {
    const dialog = modal.querySelector('.modal__inner');

    document.body.style.overflow = '';

    modal.classList.add('modal_hide');
    modal.classList.remove('modal_show');

    if (dialog) dialog.classList.remove(`modal__inner_slide-in-top`);
  }

  function showModal(modal, data) {
    const dialog = modal.querySelector('.modal__inner');
    const inject = modal.querySelector('[data-inject-input]');

    document.body.style.overflow = 'hidden';

    modal.classList.add('modal_show');
    modal.classList.remove('modal_hide');

    if (dialog) dialog.classList.add(`modal__inner_slide-in-top`);
    if (inject && data) inject.value = data;
  }

  if (modalWindows.length) {
    modalWindows.forEach((modal) => {
      const openTriggers = document.querySelectorAll(`[data-open-modal=${modal.id}]`);
      const closeTriggers = modal.querySelectorAll(`[data-close-modal]`);

      if (openTriggers) {
        openTriggers.forEach((trigger) => {
          trigger.addEventListener('click', () => showModal(modal, trigger.dataset.injectToModal));
        });
      }

      if (closeTriggers) {
        closeTriggers.forEach((trigger) => {
          trigger.addEventListener('click', () => hideModal(modal));
        });
      }

      modal.addEventListener('click', (e) => {
        if (e.target && e.target.classList.contains('modal')) {
          hideModal(modal);
        }
      });

      hideModal(modal);
    });
  } else {
    console.warn('Warning: No modals found on the page!');
  }

  // PAPYRUS
  const arrPapyrus = document.querySelectorAll('.papyrus');

  function hidePapyrus(papyrus) {
    papyrus.classList.add('papyrus_hide');
    papyrus.classList.remove('papyrus_show');
  }

  function showPapyrus(papyrus) {
    papyrus.classList.add('papyrus_show');
    papyrus.classList.remove('papyrus_hide');
  }

  if (arrPapyrus.length) {
    arrPapyrus.forEach((papyrus) => {
      const openTriggers = document.querySelectorAll(`[data-open-papyrus=${papyrus.id}]`);
      const closeTriggers = papyrus.querySelectorAll(`[data-close-papyrus]`);

      if (openTriggers) {
        openTriggers.forEach((trigger) => {
          trigger.addEventListener('click', () => {
            showPapyrus(papyrus);
            papyrus.scrollIntoView({
              block: 'start',
              behavior: 'smooth',
            });
          });
        });
      }

      if (closeTriggers) {
        closeTriggers.forEach((trigger) => {
          trigger.addEventListener('click', () => {
            hidePapyrus(papyrus);
            papyrus.scrollIntoView({
              block: 'start',
              behavior: 'smooth',
            });
          });
        });
      }

      hidePapyrus(papyrus);
    });
  } else {
    console.warn('Warning: No papyrus found on the page!');
  }

  // SLIDERS
  const ballSliders = document.querySelectorAll('.ball-slider');

  function creatSliderDots(count, container) {
    for (let i = 0; i < count; i++) {
      container.insertAdjacentHTML('beforeEnd', '<button class="ball-slider__dot"></button>');
    }
  }

  function showSliderContent(slider, content) {
    content.forEach((item, index) => {
      if (index + 1 !== slider.getInfo().displayIndex) {
        item.style.display = 'none';
      } else {
        item.style.display = 'block';
      }
    });
  }

  if (ballSliders.length) {
    ballSliders.forEach((item) => {
      const prev = item.querySelector('.ball-slider__button-prev');
      const next = item.querySelector('.ball-slider__button-next');
      const slidesCount = item.querySelectorAll('.ball-slider__slide').length;
      const dotsContainer = item.querySelector('.ball-slider__dots');
      const sliderContainer = item.querySelector('.ball-slider__container');
      const sliderContent = document.querySelectorAll(`[data-content=${item.dataset.sliderContent}]`);

      if (slidesCount) creatSliderDots(slidesCount, dotsContainer);

      const slider = tns({
        container: sliderContainer,
        items: 1,
        nav: true,
        navContainer: dotsContainer,
        prevButton: prev,
        nextButton: next,
      });

      if (sliderContent.length) showSliderContent(slider, sliderContent);

      slider.events.on('transitionStart', () => showSliderContent(slider, sliderContent));
    });
  } else {
    console.warn('Warning: No sliders found on the page!');
  }

  // SCROLL
  const scrollTop = document.querySelector('.footer-tree__scroll-top');

  function showButtonScrollTop() {
    const scrollY = window.scrollY;
    const clientHeight = document.documentElement.clientHeight;
    const scrollHeight = document.documentElement.scrollHeight;

    if (scrollY + clientHeight >= scrollHeight - 10) {
      scrollTop.classList.remove('footer-tree__scroll-top_hide');
    } else {
      scrollTop.classList.add('footer-tree__scroll-top_hide');
    }
  }

  window.addEventListener('scroll', showButtonScrollTop);

  if (scrollTop) {
    scrollTop.addEventListener('click', () => {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth',
      });
    });
  } else {
    console.warn('Warning: No scroll trigger found on the page!');
  }

  // MORE
  const arrMore = document.querySelectorAll('.more');

  function hideMore(elem) {
    elem.classList.add('more__body_hide');
    elem.classList.remove('more__body_show');
  }

  function showMore(elem) {
    elem.classList.add('more__body_show');
    elem.classList.remove('more__body_hide');
  }

  if (arrMore.length) {
    arrMore.forEach((more) => {
      const moreBody = more.querySelector('.more__body');

      more.addEventListener('click', (e) => {
        if (e.target && e.target.dataset.moreControl === 'open') showMore(moreBody);
        if (e.target && e.target.dataset.moreControl === 'close') hideMore(moreBody);
      });

      hideMore(moreBody);
    });
  } else {
    console.warn('Warning: No "more" elements found on the page!');
  }

  // MAIL
  const mail = document.querySelector('#tree-mail');

  if (mail) {
    mail.addEventListener('click', (e) => {
      if (e.target && e.target.dataset.mailControl === 'open') mail.classList.add('mail_open');
      if (e.target && e.target.dataset.mailControl === 'close') mail.classList.remove('mail_open');
    });
  } else {
    console.warn('Warning: "Mail" elemen not found on the page!');
  }
});
